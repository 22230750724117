<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <v-form
        ref="form"
        v-model="valid"
      >

        <v-row v-if="type === 'JWT'" wrap no-gutters>
          <v-col cols="12" sm="6" class="pr-sm-1">
            <v-text-field
              v-model="values.keyType"
              outlined
              dense
              :label="$lang.labels.keyType"
              required
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
              :readonly="!canEdit"
              :data-cy="`credential-${type.toLowerCase()}-keyType`"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" class="pl-sm-1">
            <v-select
              v-model="values.signatureAlgorithm"
              :items="signatureAlgorithms"
              :label="$lang.labels.signatureAlgorithm"
              outlined
              dense
              :rules="[v => !!v || $lang.labels.required]"
              class="pl-2 required-asterisk"
              :readonly="!canEdit"
              :data-cy="`credential-${type.toLowerCase()}-signatureAlgorithm`"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width">
              <v-textarea
                v-model="textareaValues.privateKey"
                rows="8"
                outlined
                dense
                hide-details
                :label="$lang.labels.privateKey"
                required
                :type="encryptionParams.privateKey.isEncrypt ? 'password' : 'text'"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || encryptionParams?.privateKey?.isEncrypt"
                :data-cy="`credential-${type.toLowerCase()}-privateKey`"
                @change="encryptionParams.privateKey.isChanged = true; formatEncryptionParams()"
              />
              <v-checkbox
                v-model="encryptionParams.privateKey.isEncrypt"
                class="ml-2 pt-1"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.privateKey.isChanged = true; !encryptionParams.privateKey.isEncrypt ? (values.privateKey = null, encryptionParams.privateKey.type = 'PLAIN') : encryptionParams.privateKey.type = 'ENCRYPT'; formatEncryptionParams()"
              />
            </div>
          </v-col>
          <v-col cols="12" class="mb-3">
            <add-key-value
              v-if="encryptionParams"
              type="jwt"
              :can-edit="canEdit"
              :data="values.payload"
              :encryption-params="encryptionFields"
              @dataChanged="values.payload = $event"
              @dataEncryptChanged="formatRestEncryptionParams($event)"
            ></add-key-value>
          </v-col>
        </v-row>

        <v-row v-if="type === 'MONGODB'" wrap no-gutters>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <div class="max-width align-center" style="position: relative">
                <v-text-field
                  v-model="values.url"
                  outlined
                  dense
                  :label="$lang.labels.url"
                  required
                  hide-details
                  :rules="[v => !!v || $lang.labels.required]"
                  class="required-asterisk"
                  :readonly="!canEdit || (encryptionParams && encryptionParams.url && encryptionParams.url.isEncrypt)"
                  :data-cy="`credential-${type.toLowerCase()}-url`"
                  :type="encryptionParams.url.isEncrypt ? 'password' : 'text'"
                  @change="encryptionParams.url.isChanged = true; formatEncryptionParams()"
                ></v-text-field>
                <v-btn
                  style="position: absolute; top: 6px; right: 10px"
                  color="accent"
                  class="ml-2"
                  icon
                  small
                  @click="openNewTab('https://getflowy.io/docs/credentials.html#mongodb')"
                >
                  <v-icon small>mdi-help</v-icon>
                </v-btn>
              </div>
              <v-checkbox
                v-model="encryptionParams.url.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.url.isChanged = true; !encryptionParams.url.isEncrypt ? (values.url = '', encryptionParams.url.type = 'PLAIN') : encryptionParams.url.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <div class="max-width align-center" style="position: relative">
                <v-textarea
                  ref="fileTextField"
                  v-model="textareaValues.clientKey"
                  prepend-icon="mdi-paperclip"
                  outlined
                  dense
                  readonly
                  hide-details
                  :label="values.clientKey ? $lang.labels.clientKey : `${$lang.labels.clientKey} ${$lang.actions.clickToImport}`"
                  class="min-w"
                  :data-cy="`credential-${type.toLowerCase()}-clientKey`"
                  :type="encryptionParams.clientKey.isEncrypt ? 'password' : 'text'"
                  @click="startInput('fileInput')"
                  @click:prepend="startInput('fileInput')"
                  @change="encryptionParams.clientKey.isChanged = true; formatEncryptionParams()"
                />
                <input
                  ref="fileInput"
                  style="display: none;"
                  type="file"
                  @change="onFileChange($event)"
                >
                <!--                <v-text-field-->
                <!--                  v-model="values.clientKey"-->
                <!--                  outlined-->
                <!--                  dense-->
                <!--                  :label="$lang.labels.clientKey"-->
                <!--                  required-->
                <!--                  hide-details-->
                <!--                  :readonly="!canEdit"-->
                <!--                  :data-cy="`credential-${type.toLowerCase()}-clientKey`"-->
                <!--                  :type="encryptionParams.clientKey.isEncrypt ? 'password' : 'text'"-->
                <!--                  @change="encryptionParams.clientKey.isChanged = true; formatEncryptionParams()"-->
                <!--                ></v-text-field>-->
                <v-btn
                  style="position: absolute; top: 6px; right: 10px"
                  color="accent"
                  class="ml-2"
                  icon
                  small
                  @click="openNewTab('https://getflowy.io/docs/credentials.html#mongodb')"
                >
                  <v-icon small>mdi-help</v-icon>
                </v-btn>
              </div>
              <v-checkbox
                v-model="encryptionParams.clientKey.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.clientKey.isChanged = true; !encryptionParams.clientKey.isEncrypt ? (values.clientKey = '', encryptionParams.clientKey.type = 'PLAIN') : encryptionParams.clientKey.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="type === 'JDBC'" wrap no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="values.maxPoolSize"
              outlined
              dense
              :label="$lang.labels.maxPoolSize"
              required
              :rules="[v => !!v || $lang.labels.required, v => /^[1-9]\d*$/.test(v) || $lang.errors.valueNotAllowed]"
              :readonly="!canEdit"
              :data-cy="`credential-${type.toLowerCase()}-maxPoolSize`"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="tempConnectionTimeout"
              outlined
              dense
              :label="$lang.labels.connectionTimeout"
              required
              :rules="[v => !!v || $lang.labels.required, v => /^[1-9]\d*$/.test(v) || $lang.errors.valueNotAllowed]"
              :readonly="!canEdit"
              :data-cy="`credential-${type.toLowerCase()}-connectionTimeout`"
              class="required-asterisk"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="connectionTimeoutUnit"
              :items="timeUnits"
              :label="$lang.labels.unit"
              outlined
              dense
              required
              class="pl-2"
              :readonly="!canEdit"
              :data-cy="`credential-${type.toLowerCase()}-connectionTimeoutUnit`"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <div class="max-width align-center" style="position: relative">
                <v-text-field
                  v-model="values.url"
                  outlined
                  dense
                  :label="$lang.labels.url"
                  required
                  hide-details
                  :type="encryptionParams.url.isEncrypt ? 'password' : 'text'"
                  :rules="[v => !!v || $lang.labels.required]"
                  :readonly="!canEdit || (encryptionParams && encryptionParams.url && encryptionParams.url.isEncrypt)"
                  :data-cy="`credential-${type.toLowerCase()}-url`"
                  class="required-asterisk"
                  @change="encryptionParams.url.isChanged = true; formatEncryptionParams()"
                ></v-text-field>
                <v-btn
                  style="position: absolute; top: 6px; right: 10px"
                  color="accent"
                  class="ml-2"
                  icon
                  small
                  @click="openNewTab('https://getflowy.io/docs/credentials.html#jdbc')"
                >
                  <v-icon small>mdi-help</v-icon>
                </v-btn>
              </div>
              <v-checkbox
                v-model="encryptionParams.url.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.url.isChanged = true; !encryptionParams.url.isEncrypt ? (values.url = '', encryptionParams.url.type = 'PLAIN') : encryptionParams.url.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.username"
                outlined
                dense
                :label="$lang.labels.username"
                required
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                :readonly="!canEdit || (encryptionParams && encryptionParams.username && encryptionParams.username.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-username`"
                :type="encryptionParams.username.isEncrypt ? 'password' : 'text'"
                class="required-asterisk"
                @change="encryptionParams.username.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.username.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.username.isChanged = true; !encryptionParams.username.isEncrypt ? (values.username = '', encryptionParams.username.type = 'PLAIN') : encryptionParams.username.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.password"
                outlined
                dense
                :label="$lang.labels.password"
                required
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                :readonly="!canEdit || (encryptionParams && encryptionParams.password && encryptionParams.password.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-password`"
                :type="encryptionParams.password.isEncrypt ? 'password' : 'text'"
                class="required-asterisk"
                @change="encryptionParams.password.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.password.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.password.isChanged = true; !encryptionParams.password.isEncrypt ? (values.password = '', encryptionParams.password.type = 'PLAIN') : encryptionParams.password.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="type === 'SMTP'" wrap no-gutters>
          <v-col cols="12" sm="6" class="pr-sm-1 pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.senderAddress"
                outlined
                dense
                hide-details
                :label="$lang.labels.senderAddress"
                required
                :rules="[rules.email]"
                :readonly="!canEdit || (encryptionParams && encryptionParams.senderAddress && encryptionParams.senderAddress.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-senderAddress`"
                :type="encryptionParams.senderAddress.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.senderAddress.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.senderAddress.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                :data-cy="`credential-${type.toLowerCase()}-senderAddress-isEncrypt`"
                @change="encryptionParams.senderAddress.isChanged = true; !encryptionParams.senderAddress.isEncrypt ? (values.senderAddress = '', encryptionParams.senderAddress.type = 'PLAIN') : encryptionParams.senderAddress.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pr-sm-1 pb-3 hidden-sm-and-down">
            &nbsp;
          </v-col>
          <v-col cols="12" sm="6" class="pr-sm-1 pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.username"
                outlined
                dense
                :label="$lang.labels.username"
                required
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                :readonly="!canEdit || (encryptionParams && encryptionParams.username && encryptionParams.username.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-username`"
                class="required-asterisk"
                :type="encryptionParams.username.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.username.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.username.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                :data-cy="`credential-${type.toLowerCase()}-username-isEncrypt`"
                @change="encryptionParams.username.isChanged = true; !encryptionParams.username.isEncrypt ? (values.username = '', encryptionParams.username.type = 'PLAIN') : encryptionParams.username.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pl-sm-1 pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.password"
                outlined
                dense
                :label="$lang.labels.password"
                required
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.password && encryptionParams.password.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-password`"
                :type="encryptionParams.password.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.password.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.password.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                :data-cy="`credential-${type.toLowerCase()}-password-isEncrypt`"
                @change="encryptionParams.password.isChanged = true; !encryptionParams.password.isEncrypt ? (values.password = '', encryptionParams.password.type = 'PLAIN') : encryptionParams.password.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pr-sm-1 pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.host"
                outlined
                dense
                :label="$lang.labels.host"
                required
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.host && encryptionParams.host.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-host`"
                :type="encryptionParams.host.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.host.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.host.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                :data-cy="`credential-${type.toLowerCase()}-host-isEncrypt`"
                @change="encryptionParams.host.isChanged = true; !encryptionParams.host.isEncrypt ? (values.host = '', encryptionParams.host.type = 'PLAIN') : encryptionParams.host.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pl-sm-1 pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.port"
                outlined
                dense
                :label="$lang.labels.port"
                required
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.port && encryptionParams.port.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-port`"
                :type="encryptionParams.port.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.port.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.port.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                :data-cy="`credential-${type.toLowerCase()}-port-isEncrypt`"
                @change="encryptionParams.port.isChanged = true; !encryptionParams.port.isEncrypt ? (values.port = '', encryptionParams.port.type = 'PLAIN') : encryptionParams.port.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="tempTimeout"
              outlined
              dense
              :label="$lang.labels.changeableTimeout"
              required
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
              :readonly="!canEdit"
              :data-cy="`credential-${type.toLowerCase()}-timeout`"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="timeoutUnit"
              :items="timeUnits"
              :label="$lang.labels.unit"
              outlined
              dense
              class="pl-2"
              :readonly="!canEdit"
              :data-cy="`credential-${type.toLowerCase()}-timeoutUnit`"
            ></v-select>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="tempConnectionTimeout"
              outlined
              dense
              :label="$lang.labels.connectionTimeout"
              required
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
              :readonly="!canEdit"
              :data-cy="`credential-${type.toLowerCase()}-connectionTimeout`"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="connectionTimeoutUnit"
              :items="timeUnits"
              :label="$lang.labels.unit"
              outlined
              dense
              class="pl-2"
              :readonly="!canEdit"
              :data-cy="`credential-${type.toLowerCase()}-connectionTimeoutUnit`"
            ></v-select>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="tempWriteTimeout"
              outlined
              dense
              :label="$lang.labels.writeTimeout"
              required
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
              :readonly="!canEdit"
              :data-cy="`credential-${type.toLowerCase()}-writeTimeout`"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="writeTimeoutUnit"
              :items="timeUnits"
              :label="$lang.labels.unit"
              outlined
              dense
              class="pl-2"
              :readonly="!canEdit"
              :data-cy="`credential-${type.toLowerCase()}-writeTimeoutUnit`"
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="type === 'TWILIO'" wrap no-gutters>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.sid"
                outlined
                dense
                :label="$lang.labels.sid"
                required
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.sid && encryptionParams.sid.isEncrypt)"
                :type="encryptionParams.sid.isEncrypt ? 'password' : 'text'"
                :data-cy="`credential-${type.toLowerCase()}-sid`"
                @change="encryptionParams.sid.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.sid.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.sid.isChanged = true; !encryptionParams.sid.isEncrypt ? (values.sid = '', encryptionParams.sid.type = 'PLAIN') : encryptionParams.sid.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.token"
                outlined
                dense
                :label="$lang.labels.token"
                required
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.token && encryptionParams.token.isEncrypt)"
                :type="encryptionParams.token.isEncrypt ? 'password' : 'text'"
                :data-cy="`credential-${type.toLowerCase()}-token`"
                @change="encryptionParams.token.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.token.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.token.isChanged = true; !encryptionParams.token.isEncrypt ? (values.token = '', encryptionParams.token.type = 'PLAIN') : encryptionParams.token.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="['REST', 'REST_BASIC', 'REST_BEARER'].includes(type)" wrap no-gutters>
          <v-col v-if="type === 'REST'" cols="12">
            <v-btn
              color="primary"
              class="mb-3"
              :data-cy="`credential-${type.toLowerCase()}-upload-p12-certificate`"
              @click="startP12CertInput()"
            >
              {{ $lang.labels.uploadCertificate }}
            </v-btn>
            <input
              ref="p12"
              style="display: none;"
              type="file"
              @change="onP12CertFileChange($event)"
            >
          </v-col>
          <v-col cols="12">
            <add-key-value
              v-if="encryptionParams && showP12"
              type="values"
              :can-edit="canEdit"
              :data="values"
              :encryption-params="encryptionFields"
              @dataChanged="values = $event"
              @dataEncryptChanged="formatRestEncryptionParams($event)"
            ></add-key-value>
          </v-col>
        </v-row>

        <v-row v-if="type === 'SLACK'" wrap no-gutters>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.channelId"
                outlined
                dense
                :label="$lang.labels.channelId"
                required
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.channelId && encryptionParams.channelId.isEncrypt)"
                :type="encryptionParams.channelId.isEncrypt ? 'password' : 'text'"
                :data-cy="`credential-${type.toLowerCase()}-channelId`"
                @change="encryptionParams.channelId.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.channelId.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.channelId.isChanged = true; !encryptionParams.channelId.isEncrypt ? (values.channelId = '', encryptionParams.channelId.type = 'PLAIN') : encryptionParams.channelId.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.token"
                outlined
                dense
                :label="$lang.labels.token"
                required
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.token && encryptionParams.token.isEncrypt)"
                :type="encryptionParams.token.isEncrypt ? 'password' : 'text'"
                :data-cy="`credential-${type.toLowerCase()}-token`"
                @change="encryptionParams.token.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.token.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.token.isChanged = true; !encryptionParams.token.isEncrypt ? (values.token = '', encryptionParams.token.type = 'PLAIN') : encryptionParams.token.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="type === 'FLOWY'" wrap no-gutters>
          <v-col cols="12">
            <v-text-field
              v-model="values.url"
              outlined
              dense
              :label="$lang.labels.url"
              required
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
              :readonly="!canEdit"
              :data-cy="`credential-${type.toLowerCase()}-url`"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.username"
                outlined
                dense
                :label="$lang.labels.username"
                required
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.username && encryptionParams.username.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-username`"
                :type="encryptionParams.username.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.username.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.username.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.username.isChanged = true; !encryptionParams.username.isEncrypt ? (values.username = '', encryptionParams.username.type = 'PLAIN') : encryptionParams.username.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.apiKey"
                outlined
                dense
                :label="$lang.labels.apiKey"
                required
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.apiKey && encryptionParams.apiKey.isEncrypt)"
                :type="encryptionParams.apiKey.isEncrypt ? 'password' : 'text'"
                :data-cy="`credential-${type.toLowerCase()}-apiKey`"
                @change="encryptionParams.apiKey.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.apiKey.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.apiKey.isChanged = true; !encryptionParams.apiKey.isEncrypt ? (values.apiKey = '', encryptionParams.apiKey.type = 'PLAIN') : encryptionParams.apiKey.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="type === 'PLUGIN'" wrap no-gutters>
          <v-col cols="12">
            <add-key-value
              v-if="encryptionParams"
              type="values"
              :can-edit="canEdit"
              :data="values"
              :encryption-params="encryptionFields"
              @dataChanged="values = $event"
              @dataEncryptChanged="formatPluginEncryptionParams($event)"
            ></add-key-value>
          </v-col>
        </v-row>

        <v-row v-if="type === 'AWS'" wrap no-gutters>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-combobox
                v-model="values.region"
                :items="regions"
                outlined
                dense
                :label="$lang.labels.region"
                required
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.region && encryptionParams.region.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-region`"
                :type="encryptionParams.region.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.region.isChanged = true; formatEncryptionParams()"
              />
              <v-checkbox
                v-model="encryptionParams.region.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.region.isChanged = true; !encryptionParams.region.isEncrypt ? (values.region = '', encryptionParams.region.type = 'PLAIN') : encryptionParams.region.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.accessKeyId"
                outlined
                dense
                :label="$lang.labels.accessKeyId"
                required
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.accessKeyId && encryptionParams.accessKeyId.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-accessKeyId`"
                :type="encryptionParams.accessKeyId.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.accessKeyId.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.accessKeyId.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.accessKeyId.isChanged = true; !encryptionParams.accessKeyId.isEncrypt ? (values.accessKeyId = '', encryptionParams.accessKeyId.type = 'PLAIN') : encryptionParams.accessKeyId.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.secretKey"
                outlined
                dense
                :label="$lang.labels.secretKey"
                required
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.secretKey && encryptionParams.secretKey.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-secretKey`"
                :type="encryptionParams.secretKey.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.secretKey.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.secretKey.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.secretKey.isChanged = true; !encryptionParams.secretKey.isEncrypt ? (values.secretKey = '', encryptionParams.secretKey.type = 'PLAIN') : encryptionParams.secretKey.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.s3Bucket"
                outlined
                dense
                :label="$lang.labels.s3Bucket"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.s3Bucket && encryptionParams.s3Bucket.isEncrypt)"
                :type="encryptionParams.s3Bucket.isEncrypt ? 'password' : 'text'"
                :data-cy="`credential-${type.toLowerCase()}-s3Bucket`"
                @change="encryptionParams.s3Bucket.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.s3Bucket.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.s3Bucket.isChanged = true; !encryptionParams.s3Bucket.isEncrypt ? (values.s3Bucket = '', encryptionParams.s3Bucket.type = 'PLAIN') : encryptionParams.s3Bucket.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="type === 'SECURITY'" wrap no-gutters>
          <v-col :key="values.passphrase || 'ABC'" cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.key"
                outlined
                dense
                :label="$lang.labels.key"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.key && encryptionParams.key.isEncrypt)"
                :rules="[v => values.passphrase ? true : !!v || 'Required!']"
                :class="values.passphrase ? '' : 'required-asterisk'"
                :data-cy="`credential-${type.toLowerCase()}-key`"
                :type="encryptionParams.key.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.key.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.key.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.key.isChanged = true; !encryptionParams.key.isEncrypt ? (values.key = '', encryptionParams.key.type = 'PLAIN') : encryptionParams.key.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col :key="values.key || 'CBA'" cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.passphrase"
                outlined
                dense
                :label="$lang.labels.passphrase"
                hide-details
                :class="values.key ? '' : 'required-asterisk'"
                :rules="[v => values.key ? true : !!v || 'Required!']"
                :readonly="!canEdit || (encryptionParams && encryptionParams.passphrase && encryptionParams.passphrase.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-key`"
                :type="encryptionParams.passphrase.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.passphrase.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.passphrase.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.passphrase.isChanged = true; !encryptionParams.passphrase.isEncrypt ? (values.passphrase = '', encryptionParams.passphrase.type = 'PLAIN') : encryptionParams.passphrase.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="type === 'SCRIPT'" wrap no-gutters>
          <v-col cols="12" class="pb-2">
            <add-key-value
              v-if="encryptionParams"
              type="values"
              :can-edit="canEdit"
              :data="values"
              :encryption-params="encryptionFields"
              @dataChanged="values = $event"
              @dataEncryptChanged="formatRestEncryptionParams($event)"
            ></add-key-value>
          </v-col>
        </v-row>

        <v-row v-if="type === 'IMAP'" wrap no-gutters>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.host"
                outlined
                dense
                :label="$lang.labels.host"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.host && encryptionParams.host.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`credential-${type.toLowerCase()}-host`"
                :type="encryptionParams.host.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.host.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.host.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.host.isChanged = true; !encryptionParams.host.isEncrypt ? (values.host = '', encryptionParams.host.type = 'PLAIN') : encryptionParams.host.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.port"
                outlined
                dense
                :label="$lang.labels.port"
                hide-details
                class="required-asterisk"
                :rules="[v => !!v || $lang.labels.required]"
                :readonly="!canEdit || (encryptionParams && encryptionParams.port && encryptionParams.port.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-port`"
                :type="encryptionParams.port.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.port.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.port.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.port.isChanged = true; !encryptionParams.port.isEncrypt ? (values.port = '', encryptionParams.port.type = 'PLAIN') : encryptionParams.port.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.username"
                outlined
                dense
                :label="$lang.labels.username"
                hide-details
                class="required-asterisk"
                :rules="[v => !!v || $lang.labels.required]"
                :readonly="!canEdit || (encryptionParams && encryptionParams.username && encryptionParams.username.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-username`"
                :type="encryptionParams.username.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.username.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.username.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.username.isChanged = true; !encryptionParams.username.isEncrypt ? (values.username = '', encryptionParams.username.type = 'PLAIN') : encryptionParams.username.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.password"
                outlined
                dense
                :label="$lang.labels.password"
                hide-details
                class="required-asterisk"
                :rules="[v => !!v || $lang.labels.required]"
                :readonly="!canEdit || (encryptionParams && encryptionParams.password && encryptionParams.password.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-password`"
                :type="encryptionParams.password.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.password.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.password.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.password.isChanged = true; !encryptionParams.password.isEncrypt ? (values.password = '', encryptionParams.password.type = 'PLAIN') : encryptionParams.password.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="type === 'KAFKA'" wrap no-gutters>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.bootstrapServers"
                outlined
                dense
                :label="$lang.labels.bootstrapServers"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.bootstrapServers && encryptionParams.bootstrapServers.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`kafka-${type.toLowerCase()}-bootstrapServers`"
                :type="encryptionParams.bootstrapServers.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.bootstrapServers.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.bootstrapServers.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.bootstrapServers.isChanged = true; !encryptionParams.bootstrapServers.isEncrypt ? (values.bootstrapServers = '', encryptionParams.bootstrapServers.type = 'PLAIN') : encryptionParams.bootstrapServers.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.groupId"
                outlined
                dense
                :label="$lang.labels.groupId"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.groupId && encryptionParams.groupId.isEncrypt)"
                :data-cy="`kafka-${type.toLowerCase()}-groupId`"
                :type="encryptionParams.groupId.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.groupId.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.groupId.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.groupId.isChanged = true; !encryptionParams.groupId.isEncrypt ? (values.groupId = '', encryptionParams.groupId.type = 'PLAIN') : encryptionParams.groupId.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.topic"
                outlined
                dense
                :label="$lang.labels.topic"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.topic && encryptionParams.topic.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`kafka-${type.toLowerCase()}-topic`"
                :type="encryptionParams.topic.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.topic.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.topic.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.topic.isChanged = true; !encryptionParams.topic.isEncrypt ? (values.topic = '', encryptionParams.topic.type = 'PLAIN') : encryptionParams.topic.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="type === 'PAYMENT_SENSE_PAC'" wrap no-gutters>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.apiKey"
                outlined
                dense
                :label="$lang.labels.apiKey"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.apiKey && encryptionParams.apiKey.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`paymentSensePac-${type.toLowerCase()}-apiKey`"
                :type="encryptionParams.apiKey.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.apiKey.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.apiKey.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.apiKey.isChanged = true; !encryptionParams.apiKey.isEncrypt ? (values.apiKey = '', encryptionParams.apiKey.type = 'PLAIN') : encryptionParams.apiKey.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.hostUrl"
                outlined
                dense
                :label="$lang.labels.hostUrl"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.hostUrl && encryptionParams.hostUrl.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`paymentSensePac-${type.toLowerCase()}-hostUrl`"
                :type="encryptionParams.hostUrl.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.hostUrl.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.hostUrl.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.hostUrl.isChanged = true; !encryptionParams.hostUrl.isEncrypt ? (values.hostUrl = '', encryptionParams.hostUrl.type = 'PLAIN') : encryptionParams.hostUrl.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.installerId"
                outlined
                dense
                :label="$lang.labels.installerId"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.installerId && encryptionParams.installerId.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`paymentSensePac-${type.toLowerCase()}-installerId`"
                :type="encryptionParams.installerId.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.installerId.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.installerId.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.installerId.isChanged = true; !encryptionParams.installerId.isEncrypt ? (values.installerId = '', encryptionParams.installerId.type = 'PLAIN') : encryptionParams.installerId.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.softwareHouseId"
                outlined
                dense
                :label="$lang.labels.softwareHouseId"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.softwareHouseId && encryptionParams.softwareHouseId.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`paymentSensePac-${type.toLowerCase()}-softwareHouseId`"
                :type="encryptionParams.softwareHouseId.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.softwareHouseId.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.softwareHouseId.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.softwareHouseId.isChanged = true; !encryptionParams.softwareHouseId.isEncrypt ? (values.softwareHouseId = '', encryptionParams.softwareHouseId.type = 'PLAIN') : encryptionParams.softwareHouseId.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.username"
                outlined
                dense
                :label="$lang.labels.username"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.username && encryptionParams.username.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`paymentSensePac-${type.toLowerCase()}-username`"
                :type="encryptionParams.username.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.username.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.username.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.username.isChanged = true; !encryptionParams.username.isEncrypt ? (values.username = '', encryptionParams.username.type = 'PLAIN') : encryptionParams.username.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="type === 'JMS'" wrap no-gutters>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.uri"
                outlined
                dense
                :label="$lang.labels.uri"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.uri && encryptionParams.uri.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`jms-${type.toLowerCase()}-uri`"
                :type="encryptionParams.uri.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.uri.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.uri.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.uri.isChanged = true; !encryptionParams.uri.isEncrypt ? (values.uri = '', encryptionParams.uri.type = 'PLAIN') : encryptionParams.uri.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.username"
                outlined
                dense
                :label="$lang.labels.username"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.username && encryptionParams.username.isEncrypt)"
                :data-cy="`jms-${type.toLowerCase()}-username`"
                :type="encryptionParams.username.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.username.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.username.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.username.isChanged = true; !encryptionParams.username.isEncrypt ? (values.username = '', encryptionParams.username.type = 'PLAIN') : encryptionParams.username.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.password"
                outlined
                dense
                :label="$lang.labels.password"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.password && encryptionParams.password.isEncrypt)"
                :data-cy="`jms-${type.toLowerCase()}-password`"
                :type="encryptionParams.password.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.password.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.password.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.password.isChanged = true; !encryptionParams.password.isEncrypt ? (values.password = '', encryptionParams.password.type = 'PLAIN') : encryptionParams.password.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="10" class="pb-1">
            <v-select
              v-model="values.destinationType"
              :items="['QUEUE', 'TOPIC']"
              :label="$lang.labels.destinationType"
              outlined
              dense
              required
              class="required-asterisk"
              :readonly="!canEdit"
              :data-cy="`credential-${type.toLowerCase()}-destinationType`"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.destinationName"
                outlined
                dense
                :label="$lang.labels.destinationName"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.destinationName && encryptionParams.destinationName.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`jms-${type.toLowerCase()}-topic`"
                :type="encryptionParams.destinationName.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.destinationName.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.destinationName.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.destinationName.isChanged = true; !encryptionParams.destinationName.isEncrypt ? (values.topic = '', encryptionParams.destinationName.type = 'PLAIN') : encryptionParams.destinationName.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="type === 'PAYMENT_SENSE_CONNECT_E'" wrap no-gutters>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.hostUrl"
                outlined
                dense
                :label="$lang.labels.hostUrl"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.hostUrl && encryptionParams.hostUrl.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`paymentSenseConnectE-${type.toLowerCase()}-hostUrl`"
                :type="encryptionParams.hostUrl.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.hostUrl.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.hostUrl.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.hostUrl.isChanged = true; !encryptionParams.hostUrl.isEncrypt ? (values.hostUrl = '', encryptionParams.hostUrl.type = 'PLAIN') : encryptionParams.hostUrl.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.merchantUrl"
                outlined
                dense
                :label="$lang.labels.merchantUrl"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.merchantUrl && encryptionParams.merchantUrl.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`paymentSenseConnectE-${type.toLowerCase()}-installerId`"
                :type="encryptionParams.merchantUrl.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.merchantUrl.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.merchantUrl.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.merchantUrl.isChanged = true; !encryptionParams.merchantUrl.isEncrypt ? (values.merchantUrl = '', encryptionParams.merchantUrl.type = 'PLAIN') : encryptionParams.merchantUrl.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.jwt"
                outlined
                dense
                :label="$lang.labels.jwt"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.jwt && encryptionParams.jwt.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`paymentSenseConnectE-${type.toLowerCase()}-jwt`"
                :type="encryptionParams.jwt.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.jwt.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.jwt.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.jwt.isChanged = true; !encryptionParams.jwt.isEncrypt ? (values.jwt = '', encryptionParams.jwt.type = 'PLAIN') : encryptionParams.jwt.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="type === 'RABBITMQ'" wrap no-gutters>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.exchange"
                outlined
                dense
                :label="$lang.labels.exchange"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.exchange && encryptionParams.exchange.isEncrypt)"
                :data-cy="`rabbitmq-${type.toLowerCase()}-exchange`"
                :type="encryptionParams.exchange.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.exchange.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.exchange.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.exchange.isChanged = true; !encryptionParams.exchange.isEncrypt ? (values.exchange = '', encryptionParams.exchange.type = 'PLAIN') : encryptionParams.exchange.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.host"
                outlined
                dense
                :label="$lang.labels.host"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.host && encryptionParams.host.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`rabbitmq-${type.toLowerCase()}-host`"
                :type="encryptionParams.host.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.host.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.host.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.host.isChanged = true; !encryptionParams.host.isEncrypt ? (values.host = '', encryptionParams.host.type = 'PLAIN') : encryptionParams.host.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.port"
                outlined
                dense
                :label="$lang.labels.port"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.port && encryptionParams.port.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`rabbitmq-${type.toLowerCase()}-port`"
                :type="encryptionParams.port.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.port.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.port.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.port.isChanged = true; !encryptionParams.port.isEncrypt ? (values.port = '', encryptionParams.port.type = 'PLAIN') : encryptionParams.port.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.password"
                outlined
                dense
                :label="$lang.labels.password"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.password && encryptionParams.password.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`rabbitmq-${type.toLowerCase()}-password`"
                :type="encryptionParams.password.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.password.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.password.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.password.isChanged = true; !encryptionParams.password.isEncrypt ? (values.password = '', encryptionParams.password.type = 'PLAIN') : encryptionParams.password.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.username"
                outlined
                dense
                :label="$lang.labels.username"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.username && encryptionParams.username.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`rabbitmq-${type.toLowerCase()}-username`"
                :type="encryptionParams.username.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.username.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.username.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.username.isChanged = true; !encryptionParams.username.isEncrypt ? (values.username = '', encryptionParams.username.type = 'PLAIN') : encryptionParams.username.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.virtualHost"
                outlined
                dense
                :label="$lang.labels.virtualHost"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.virtualHost && encryptionParams.virtualHost.isEncrypt)"
                :data-cy="`rabbitmq-${type.toLowerCase()}-virtualHost`"
                :type="encryptionParams.virtualHost.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.virtualHost.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.virtualHost.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.virtualHost.isChanged = true; !encryptionParams.virtualHost.isEncrypt ? (values.virtualHost = '', encryptionParams.virtualHost.type = 'PLAIN') : encryptionParams.virtualHost.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.routingKey"
                outlined
                dense
                :label="$lang.labels.routingKey"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.routingKey && encryptionParams.routingKey.isEncrypt)"
                :data-cy="`rabbitmq-${type.toLowerCase()}-routingKey`"
                :type="encryptionParams.routingKey.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.routingKey.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.routingKey.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.routingKey.isChanged = true; !encryptionParams.routingKey.isEncrypt ? (values.routingKey = '', encryptionParams.routingKey.type = 'PLAIN') : encryptionParams.routingKey.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <v-checkbox
              v-model="values.useSslProtocol"
              hide-details
              dense
              :label="$lang.labels.useSslProtocol"
            />
          </v-col>
        </v-row>

        <v-row v-if="type === 'MQTT'" wrap no-gutters>
          <v-col cols="12" sm="6" class="pr-sm-1">
            <v-select
              v-model="values.scheme"
              :items="mqttScheme"
              :label="$lang.labels.scheme"
              outlined
              dense
              :rules="[v => !!v || $lang.labels.required]"
              class="required-asterisk"
              :readonly="!canEdit"
              :data-cy="`credential-${type.toLowerCase()}-scheme`"
            ></v-select>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.host"
                outlined
                dense
                :label="$lang.labels.host"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.host && encryptionParams.host.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`credential-${type.toLowerCase()}-host`"
                :type="encryptionParams.host.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.host.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.host.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.host.isChanged = true; !encryptionParams.host.isEncrypt ? (values.host = '', encryptionParams.host.type = 'PLAIN') : encryptionParams.host.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.port"
                outlined
                dense
                :label="$lang.labels.port"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.port && encryptionParams.port.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`credential-${type.toLowerCase()}-port`"
                :type="encryptionParams.port.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.port.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.port.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.port.isChanged = true; !encryptionParams.port.isEncrypt ? (values.port = '', encryptionParams.port.type = 'PLAIN') : encryptionParams.port.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.topic"
                outlined
                dense
                :label="$lang.labels.topic"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.topic && encryptionParams.topic.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`credential-${type.toLowerCase()}-topic`"
                :type="encryptionParams.topic.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.topic.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.topic.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.topic.isChanged = true; !encryptionParams.topic.isEncrypt ? (values.topic = '', encryptionParams.topic.type = 'PLAIN') : encryptionParams.topic.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.username"
                outlined
                dense
                :label="$lang.labels.username"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.username && encryptionParams.username.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-username`"
                :type="encryptionParams.username.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.username.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.username.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.username.isChanged = true; !encryptionParams.username.isEncrypt ? (values.username = '', encryptionParams.username.type = 'PLAIN') : encryptionParams.username.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.password"
                outlined
                dense
                :label="$lang.labels.password"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.password && encryptionParams.password.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-password`"
                :type="encryptionParams.password.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.password.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.password.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.password.isChanged = true; !encryptionParams.password.isEncrypt ? (values.password = '', encryptionParams.password.type = 'PLAIN') : encryptionParams.password.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.clientId"
                outlined
                dense
                :label="$lang.labels.clientId"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.clientId && encryptionParams.clientId.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-clientId`"
                :type="encryptionParams.clientId.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.clientId.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.clientId.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.clientId.isChanged = true; !encryptionParams.clientId.isEncrypt ? (values.clientId = '', encryptionParams.clientId.type = 'PLAIN') : encryptionParams.clientId.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center position-relative">
              <v-textarea
                v-model="textareaValues.rootCertificate"
                outlined
                dense
                :label="$lang.labels.rootCertificate"
                hide-details
                rows="5"
                :readonly="!canEdit || (encryptionParams && encryptionParams.rootCertificate && encryptionParams.rootCertificate.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-rootCertificate`"
                :type="encryptionParams.rootCertificate.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.rootCertificate.isChanged = true; formatEncryptionParams()"
              />
              <v-btn
                v-if="canEdit"
                :data-cy="`credential-${type.toLowerCase()}-rootCertificate-upload`"
                icon
                absolute
                color="primary"
                style="right: 116px; top: 8px"
                @click="startCertInput('certFileInput', 'rootCertificate')"
              >
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
              <v-checkbox
                v-model="encryptionParams.rootCertificate.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.rootCertificate.isChanged = true; !encryptionParams.rootCertificate.isEncrypt ? (values.rootCertificate = '', encryptionParams.rootCertificate.type = 'PLAIN') : encryptionParams.rootCertificate.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.rootCertificatePassword"
                outlined
                dense
                :label="$lang.labels.rootCertificatePassword"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.rootCertificatePassword && encryptionParams.rootCertificatePassword.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-rootCertificatePassword`"
                :type="encryptionParams.rootCertificatePassword.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.rootCertificatePassword.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.rootCertificatePassword.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.rootCertificatePassword.isChanged = true; !encryptionParams.rootCertificatePassword.isEncrypt ? (values.rootCertificatePassword = '', encryptionParams.rootCertificatePassword.type = 'PLAIN') : encryptionParams.rootCertificatePassword.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center position-relative">
              <v-textarea
                v-model="textareaValues.clientCertificate"
                outlined
                dense
                rows="5"
                :label="$lang.labels.clientCertificate"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.clientCertificate && encryptionParams.clientCertificate.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-clientCertificate`"
                :type="encryptionParams.clientCertificate.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.clientCertificate.isChanged = true; formatEncryptionParams()"
              />
              <v-btn
                v-if="canEdit"
                :data-cy="`credential-${type.toLowerCase()}-clientCertificate-upload`"
                icon
                absolute
                color="primary"
                style="right: 116px; top: 8px"
                @click="startCertInput('certFileInput', 'clientCertificate')"
              >
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
              <v-checkbox
                v-model="encryptionParams.clientCertificate.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.clientCertificate.isChanged = true; !encryptionParams.clientCertificate.isEncrypt ? (values.clientCertificate = '', encryptionParams.clientCertificate.type = 'PLAIN') : encryptionParams.clientCertificate.type = 'ENCRYPT'; formatEncryptionParams()"
              />
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center position-relative">
              <v-textarea
                v-model="textareaValues.clientPrivateKey"
                outlined
                dense
                rows="5"
                :label="$lang.labels.clientPrivateKey"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.clientPrivateKey && encryptionParams.clientPrivateKey.isEncrypt)"
                :data-cy="`credential-${type.toLowerCase()}-clientPrivateKey`"
                :type="encryptionParams.clientPrivateKey.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.clientPrivateKey.isChanged = true; formatEncryptionParams()"
              />
              <v-btn
                v-if="canEdit"
                :data-cy="`credential-${type.toLowerCase()}-clientPrivateKey-upload`"
                icon
                absolute
                color="primary"
                style="right: 116px; top: 8px"
                @click="startCertInput('certFileInput', 'clientPrivateKey')"
              >
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
              <v-checkbox
                v-model="encryptionParams.clientPrivateKey.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.clientPrivateKey.isChanged = true; !encryptionParams.clientPrivateKey.isEncrypt ? (values.clientPrivateKey = null, encryptionParams.clientPrivateKey.type = 'PLAIN') : encryptionParams.clientPrivateKey.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <input
            ref="certFileInput"
            style="display: none;"
            type="file"
            @change="onCertFileChange($event)"
          >
        </v-row>

        <v-row v-if="type === 'FTP'" wrap no-gutters>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.host"
                outlined
                dense
                :label="$lang.labels.host"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.host && encryptionParams.host.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`ftp-${type.toLowerCase()}-host`"
                :type="encryptionParams.host.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.host.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.host.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.host.isChanged = true; !encryptionParams.host.isEncrypt ? (values.host = '', encryptionParams.host.type = 'PLAIN') : encryptionParams.host.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.port"
                outlined
                dense
                :label="$lang.labels.port"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.port && encryptionParams.port.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`ftp-${type.toLowerCase()}-port`"
                :type="encryptionParams.port.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.port.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.port.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.port.isChanged = true; !encryptionParams.port.isEncrypt ? (values.port = '', encryptionParams.port.type = 'PLAIN') : encryptionParams.port.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.username"
                outlined
                dense
                :label="$lang.labels.username"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.username && encryptionParams.username.isEncrypt)"
                :data-cy="`ftp-${type.toLowerCase()}-username`"
                :type="encryptionParams.username.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.username.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.username.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.username.isChanged = true; !encryptionParams.username.isEncrypt ? (values.username = '', encryptionParams.username.type = 'PLAIN') : encryptionParams.username.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.password"
                outlined
                dense
                :label="$lang.labels.password"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.password && encryptionParams.password.isEncrypt)"
                :data-cy="`ftp-${type.toLowerCase()}-password`"
                :type="encryptionParams.password.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.password.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.password.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.password.isChanged = true; !encryptionParams.password.isEncrypt ? (values.password = '', encryptionParams.password.type = 'PLAIN') : encryptionParams.password.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="type === 'SFTP'" wrap no-gutters>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.host"
                outlined
                dense
                :label="$lang.labels.host"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.host && encryptionParams.host.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`sftp-${type.toLowerCase()}-host`"
                :type="encryptionParams.host.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.host.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.host.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.host.isChanged = true; !encryptionParams.host.isEncrypt ? (values.host = '', encryptionParams.host.type = 'PLAIN') : encryptionParams.host.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.port"
                outlined
                dense
                :label="$lang.labels.port"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.port && encryptionParams.port.isEncrypt)"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :data-cy="`sftp-${type.toLowerCase()}-port`"
                :type="encryptionParams.port.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.port.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.port.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.port.isChanged = true; !encryptionParams.port.isEncrypt ? (values.port = '', encryptionParams.port.type = 'PLAIN') : encryptionParams.port.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.username"
                outlined
                dense
                :label="$lang.labels.username"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.username && encryptionParams.username.isEncrypt)"
                :data-cy="`sftp-${type.toLowerCase()}-username`"
                :type="encryptionParams.username.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.username.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.username.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.username.isChanged = true; !encryptionParams.username.isEncrypt ? (values.username = '', encryptionParams.username.type = 'PLAIN') : encryptionParams.username.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.password"
                outlined
                dense
                :label="$lang.labels.password"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.password && encryptionParams.password.isEncrypt)"
                :data-cy="`sftp-${type.toLowerCase()}-password`"
                :type="encryptionParams.password.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.password.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.password.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.password.isChanged = true; !encryptionParams.password.isEncrypt ? (values.password = '', encryptionParams.password.type = 'PLAIN') : encryptionParams.password.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center position-relative">
              <v-textarea
                v-model="textareaValues.privateKey"
                outlined
                dense
                rows="5"
                :label="$lang.labels.privateKey"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.privateKey && encryptionParams.privateKey.isEncrypt)"
                :data-cy="`sftp-${type.toLowerCase()}-privateKey`"
                :type="encryptionParams.privateKey.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.privateKey.isChanged = true; formatEncryptionParams()"
              />
              <v-btn
                v-if="canEdit"
                :data-cy="`credential-${type.toLowerCase()}-privateKey-upload`"
                icon
                absolute
                color="primary"
                style="right: 116px; top: 8px"
                @click="startCertInput('certFileInput1', 'privateKey')"
              >
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
              <v-checkbox
                v-model="encryptionParams.privateKey.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.privateKey.isChanged = true; !encryptionParams.privateKey.isEncrypt ? (values.privateKey = null, encryptionParams.privateKey.type = 'PLAIN') : encryptionParams.privateKey.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
            <input
              ref="certFileInput1"
              style="display: none;"
              type="file"
              @change="onCertFileChange($event)"
            >
          </v-col>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center position-relative">
              <v-textarea
                v-model="textareaValues.passphrase"
                outlined
                dense
                rows="5"
                :label="$lang.labels.passphrase"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.passphrase && encryptionParams.passphrase.isEncrypt)"
                :data-cy="`sftp-${type.toLowerCase()}-passphrase`"
                :type="encryptionParams.passphrase.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.passphrase.isChanged = true; formatEncryptionParams()"
              />
              <v-btn
                v-if="canEdit"
                :data-cy="`credential-${type.toLowerCase()}-passphrase-upload`"
                icon
                absolute
                color="primary"
                style="right: 116px; top: 8px"
                @click="startCertInput('certFileInput2', 'passphrase')"
              >
                <v-icon>mdi-paperclip</v-icon>
              </v-btn>
              <v-checkbox
                v-model="encryptionParams.passphrase.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.passphrase.isChanged = true; !encryptionParams.passphrase.isEncrypt ? (values.passphrase = null, encryptionParams.passphrase.type = 'PLAIN') : encryptionParams.passphrase.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
            <input
              ref="certFileInput2"
              style="display: none;"
              type="file"
              @change="onCertFileChange($event)"
            >
          </v-col>
        </v-row>

        <v-row v-if="type === 'OPEN_AI'" wrap no-gutters>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.token"
                outlined
                dense
                :label="$lang.labels.token"
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.token && encryptionParams.token.isEncrypt)"
                :data-cy="`open_ai-${type.toLowerCase()}-token`"
                :type="encryptionParams.token.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.token.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.token.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.token.isChanged = true; !encryptionParams.token.isEncrypt ? (values.token = '', encryptionParams.token.type = 'PLAIN') : encryptionParams.token.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="type === 'LDAP'" wrap no-gutters>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.host"
                outlined
                dense
                :label="$lang.labels.host"
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.host && encryptionParams.host.isEncrypt)"
                :data-cy="`ldap-${type.toLowerCase()}-host`"
                :type="encryptionParams.host.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.host.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.host.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.host.isChanged = true; !encryptionParams.host.isEncrypt ? (values.host = '', encryptionParams.host.type = 'PLAIN') : encryptionParams.host.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>

          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.port"
                outlined
                dense
                :label="$lang.labels.port"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.port && encryptionParams.port.isEncrypt)"
                :data-cy="`ldap-${type.toLowerCase()}-port`"
                :type="encryptionParams.port.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.port.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.port.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.port.isChanged = true; !encryptionParams.port.isEncrypt ? (values.port = '', encryptionParams.port.type = 'PLAIN') : encryptionParams.port.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>

          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.baseDn"
                outlined
                dense
                :label="$lang.labels.baseDn"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.baseDn && encryptionParams.baseDn.isEncrypt)"
                :data-cy="`ldap-${type.toLowerCase()}-baseDn`"
                :type="encryptionParams.baseDn.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.baseDn.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.baseDn.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.baseDn.isChanged = true; !encryptionParams.baseDn.isEncrypt ? (values.baseDn = '', encryptionParams.baseDn.type = 'PLAIN') : encryptionParams.baseDn.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>

          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.userDn"
                outlined
                dense
                :label="$lang.labels.userDn"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.userDn && encryptionParams.userDn.isEncrypt)"
                :data-cy="`ldap-${type.toLowerCase()}-userDn`"
                :type="encryptionParams.userDn.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.userDn.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.userDn.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.userDn.isChanged = true; !encryptionParams.userDn.isEncrypt ? (values.userDn = '', encryptionParams.userDn.type = 'PLAIN') : encryptionParams.userDn.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>

          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.password"
                outlined
                dense
                :label="$lang.labels.password"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.password && encryptionParams.password.isEncrypt)"
                :data-cy="`ldap-${type.toLowerCase()}-password`"
                :type="encryptionParams.password.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.password.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.password.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.password.isChanged = true; !encryptionParams.password.isEncrypt ? (values.password = '', encryptionParams.password.type = 'PLAIN') : encryptionParams.password.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>

          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-textarea
                v-model="textareaValues.certificate"
                outlined
                dense
                rows="3"
                auto-grow
                :label="$lang.labels.certificate"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.certificate && encryptionParams.certificate.isEncrypt)"
                :data-cy="`ldap-${type.toLowerCase()}-certificate`"
                :type="encryptionParams.certificate.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.certificate.isChanged = true; formatEncryptionParams()"
              />
              <v-checkbox
                v-model="encryptionParams.certificate.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.certificate.isChanged = true; !encryptionParams.certificate.isEncrypt ? (values.certificate = '', encryptionParams.certificate.type = 'PLAIN') : encryptionParams.certificate.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="type === 'SSH'" wrap no-gutters>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.host"
                outlined
                dense
                :label="$lang.labels.host"
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.host && encryptionParams.host.isEncrypt)"
                :data-cy="`ssh-${type.toLowerCase()}-host`"
                :type="encryptionParams.host.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.host.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.host.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.host.isChanged = true; !encryptionParams.host.isEncrypt ? (values.host = '', encryptionParams.host.type = 'PLAIN') : encryptionParams.host.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>

          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.port"
                outlined
                dense
                :label="$lang.labels.port"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.port && encryptionParams.port.isEncrypt)"
                :data-cy="`ssh-${type.toLowerCase()}-port`"
                :type="encryptionParams.port.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.port.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.port.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.port.isChanged = true; !encryptionParams.port.isEncrypt ? (values.port = '', encryptionParams.port.type = 'PLAIN') : encryptionParams.port.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>

          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.username"
                outlined
                dense
                :label="$lang.labels.username"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.username && encryptionParams.username.isEncrypt)"
                :data-cy="`ssh-${type.toLowerCase()}-username`"
                :type="encryptionParams.password.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.username.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.username.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.username.isChanged = true; !encryptionParams.username.isEncrypt ? (values.username = '', encryptionParams.username.type = 'PLAIN') : encryptionParams.username.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>

          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.password"
                outlined
                dense
                :label="$lang.labels.password"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.password && encryptionParams.password.isEncrypt)"
                :data-cy="`ssh-${type.toLowerCase()}-password`"
                :type="encryptionParams.password.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.password.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.password.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.password.isChanged = true; !encryptionParams.password.isEncrypt ? (values.password = '', encryptionParams.password.type = 'PLAIN') : encryptionParams.password.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="type === 'SSH_KEY'" wrap no-gutters>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.host"
                outlined
                dense
                :label="$lang.labels.host"
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.host && encryptionParams.host.isEncrypt)"
                :data-cy="`ssh-key-${type.toLowerCase()}-host`"
                :type="encryptionParams.host.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.host.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.host.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.host.isChanged = true; !encryptionParams.host.isEncrypt ? (values.host = '', encryptionParams.host.type = 'PLAIN') : encryptionParams.host.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>

          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.port"
                outlined
                dense
                :label="$lang.labels.port"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.port && encryptionParams.port.isEncrypt)"
                :data-cy="`ssh-key-${type.toLowerCase()}-port`"
                :type="encryptionParams.port.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.port.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.port.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.port.isChanged = true; !encryptionParams.port.isEncrypt ? (values.port = '', encryptionParams.port.type = 'PLAIN') : encryptionParams.port.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>

          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.username"
                outlined
                dense
                :label="$lang.labels.username"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.username && encryptionParams.username.isEncrypt)"
                :data-cy="`ssh-key-${type.toLowerCase()}-username`"
                :type="encryptionParams.username.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.username.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.username.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.username.isChanged = true; !encryptionParams.username.isEncrypt ? (values.username = '', encryptionParams.username.type = 'PLAIN') : encryptionParams.username.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>

          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.password"
                outlined
                dense
                :label="$lang.labels.password"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.password && encryptionParams.password.isEncrypt)"
                :data-cy="`ssh-key-${type.toLowerCase()}-password`"
                :type="encryptionParams.password.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.password.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.password.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.password.isChanged = true; !encryptionParams.password.isEncrypt ? (values.password = '', encryptionParams.password.type = 'PLAIN') : encryptionParams.password.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>

          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.privateKey"
                outlined
                dense
                :label="$lang.labels.privateKey"
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.privateKey && encryptionParams.privateKey.isEncrypt)"
                :data-cy="`ssh-key-${type.toLowerCase()}-privateKey`"
                :type="encryptionParams.privateKey.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.privateKey.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.privateKey.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.privateKey.isChanged = true; !encryptionParams.privateKey.isEncrypt ? (values.privateKey = '', encryptionParams.privateKey.type = 'PLAIN') : encryptionParams.privateKey.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>

          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.passphrase"
                outlined
                dense
                :label="$lang.labels.passphrase"
                hide-details
                :readonly="!canEdit || (encryptionParams && encryptionParams.passphrase && encryptionParams.passphrase.isEncrypt)"
                :data-cy="`ssh-key-${type.toLowerCase()}-passphrase`"
                :type="encryptionParams.passphrase.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.passphrase.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.passphrase.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.passphrase.isChanged = true; !encryptionParams.passphrase.isEncrypt ? (values.passphrase = '', encryptionParams.passphrase.type = 'PLAIN') : encryptionParams.passphrase.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="type === 'OUTLOOK_CALENDAR'" wrap no-gutters>
          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.tenant"
                outlined
                dense
                :label="$lang.labels.tenant"
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.tenant && encryptionParams.tenant.isEncrypt)"
                :data-cy="`outlook_calendar-key-${type.toLowerCase()}-tenant`"
                :type="encryptionParams.tenant.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.tenant.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.tenant.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.tenant.isChanged = true; !encryptionParams.tenant.isEncrypt ? (values.tenant = '', encryptionParams.tenant.type = 'PLAIN') : encryptionParams.tenant.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>

          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.clientId"
                outlined
                dense
                :label="$lang.labels.clientId"
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.clientId && encryptionParams.clientId.isEncrypt)"
                :data-cy="`outlook_calendar-key-${type.toLowerCase()}-clientId`"
                :type="encryptionParams.clientId.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.clientId.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.clientId.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.clientId.isChanged = true; !encryptionParams.clientId.isEncrypt ? (values.clientId = '', encryptionParams.clientId.type = 'PLAIN') : encryptionParams.clientId.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>

          <v-col cols="12" class="pb-3">
            <div class="d-inline-flex max-width align-center">
              <v-text-field
                v-model="values.clientSecret"
                outlined
                dense
                :label="$lang.labels.clientSecret"
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                class="required-asterisk"
                :readonly="!canEdit || (encryptionParams && encryptionParams.clientSecret && encryptionParams.clientSecret.isEncrypt)"
                :data-cy="`outlook_calendar-key-${type.toLowerCase()}-clientSecret`"
                :type="encryptionParams.clientSecret.isEncrypt ? 'password' : 'text'"
                @change="encryptionParams.clientSecret.isChanged = true; formatEncryptionParams()"
              ></v-text-field>
              <v-checkbox
                v-model="encryptionParams.clientSecret.isEncrypt"
                class="ml-2"
                hide-details
                dense
                :label="$lang.labels.encrypt"
                @change="encryptionParams.clientSecret.isChanged = true; !encryptionParams.clientSecret.isEncrypt ? (values.clientSecret = '', encryptionParams.clientSecret.type = 'PLAIN') : encryptionParams.clientSecret.type = 'ENCRYPT'; formatEncryptionParams()"
              ></v-checkbox>
            </div>
          </v-col>

        </v-row>

      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import addKeyValue from './KeyValPairModal'
import { predictBestTimeUnitFromMsValue } from '@/utils/helpers'
import { definitions } from '@/utils/definitions'
import { regions } from '@/utils/constants'

export default {
  components: {
    addKeyValue
  },
  props: {
    type: {
      type: String,
      default: () => ''
    },
    isEdit: {
      type: Boolean,
      default: () => false
    },
    isCopy: {
      type: Boolean,
      default: () => false
    },
    defaultValues: {
      type: Object,
      default: () => {
      }
    },
    canEdit: {
      type: Boolean,
      default: () => false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    encryptionFields: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      showP12: true,
      tempValuesKey: 1,
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

          return pattern.test(value) || 'Invalid e-mail.'
        }
      },
      valid: false,
      values: {
        keyType: '',
        payload: {
          api_version: '',
          app_id: '',
          aud: ''
        },
        privateKey: null,
        signatureAlgorithm: '',
        url: '',
        clientKey: '',
        maxPoolSize: '8',
        host: '',
        password: '',
        port: '',
        username: '',
        token: '',
        sid: '',
        clientId: '',
        clientSecret: '',
        channelId: '',
        connectionTimeout: '10000',
        timeout: '10000',
        writeTimeout: '10000',
        apiKey: '',
        accessKeyId: '',
        region: '',
        s3Bucket: '',
        secretKey: '',
        key: '',
        passphrase: '',
        topic: '',
        groupId: '',
        bootstrapServers: '',
        hostUrl: '',
        installerId: '',
        softwareHouseId: '',
        uri: '',
        destinationName: '',
        destinationType: 'QUEUE',
        jwt: '',
        merchantUrl: '',
        exchange: '',
        virtualHost: '',
        routingKey: '',
        useSslProtocol: false,
        senderAddress: '',
        scheme: '',
        rootCertificate: '',
        rootCertificatePassword: '',
        clientCertificate: '',
        clientPrivateKey: null,
        baseDn: '',
        userDn: '',
        certificate: '',
        tenant: ''
      },
      tempConnectionTimeout: '10',
      tempTimeout: '10',
      tempWriteTimeout: '10',
      connectionTimeoutUnit: 'Sec',
      timeoutUnit: 'Sec',
      writeTimeoutUnit: 'Sec',
      currentConnectionTimeoutUnit: 'Sec',
      currentTimeoutUnit: 'Sec',
      currentWriteTimeoutUnit: 'Sec',
      timeUnits: ['MSec', 'Sec', 'Min'],
      initialLoading: false,
      encryptionParams: {},
      signatureAlgorithms: definitions.JwtCredentialValues.properties.signatureAlgorithm.enum,
      mqttScheme: definitions.MqttCredentialValues.properties.scheme.enum,
      regions: regions,
      uploadField: '',
      textareaValues: {
        privateKey: null,
        clientKey: null,
        rootCertificate: null,
        clientCertificate: null,
        clientPrivateKey: null,
        passphrase: null,
        certificate: null
      }
    }
  },
  computed: {
    textareaKeys() {
      return Object.keys(this.textareaValues)
    }
  },
  watch: {
    writeTimeoutUnit: {
      handler(val) {
        this.values.writeTimeout = this.$options.filters.unitFormatter(this.tempWriteTimeout, val, 'MSec')
        this.currentWriteTimeoutUnit = val
      }
    },
    tempWriteTimeout: {
      handler(val) {
        this.values.writeTimeout = this.$options.filters.unitFormatter(val, this.writeTimeoutUnit, 'MSec')
      }
    },
    timeoutUnit: {
      handler(val) {
        this.values.timeout = this.$options.filters.unitFormatter(this.tempTimeout, val, 'MSec')
        this.currentTimeoutUnit = val
      }
    },
    tempTimeout: {
      handler(val) {
        this.values.timeout = this.$options.filters.unitFormatter(val, this.timeoutUnit, 'MSec')
      }
    },
    connectionTimeoutUnit: {
      handler(val) {
        this.values.connectionTimeout = this.$options.filters.unitFormatter(this.tempConnectionTimeout, val, 'MSec')
        this.currentConnectionTimeoutUnit = val
      }
    },
    tempConnectionTimeout: {
      handler(val) {
        this.values.connectionTimeout = this.$options.filters.unitFormatter(val, this.connectionTimeoutUnit, 'MSec')
      }
    },
    valid: {
      handler(val) {
        this.$emit('setValid', val)
      },
      deep: true
    },
    values: {
      handler() {
        if (!this.loading && this.initialLoading) {
          this.$emit('setValues', this.formatValues())
        }
      },
      deep: true
    },
    textareaValues: {
      handler() {
        this.handleVisibilityTextareaValues()
        this.$emit('setValues', this.formatValues())
      },
      deep: true,
      immediate: true
    },
    encryptionFields: {
      handler() {
        this.handleVisibilityTextareaValues()
      },
      deep: true
    }
  },
  created() {
    if ((this.isEdit || this.isCopy) && this.defaultValues) {
      this.values = JSON.parse(JSON.stringify(this.defaultValues))
      this.$emit('setValues', this.formatValues())
    }
    if (!this.isEdit && !this.isCopy && ['REST', 'PLUGIN', 'SCRIPT', 'MQTT'].includes(this.type)) this.values = {}
    if (!this.isEdit && !this.isCopy && ['JWT'].includes(this.type)) this.values = {
      keyType: '',
      signatureAlgorithm: '',
      privateKey: null,
      payload: {}
    }
    if (!this.isEdit && !this.isCopy && ['REST_BASIC'].includes(this.type)) this.values = {
      username: '',
      password: ''
    }
    if (!this.isEdit && !this.isCopy && ['REST_BEARER'].includes(this.type)) this.values = {
      token: ''
    }

    this.formatTimeUnits()

    this.encryptionFields.forEach((field) => {
      this.encryptionParams[field.field] = field
    })

    if (this.type === 'FTP' || this.type === 'SFTP') {
      this.textareaKeys.forEach((key) => {
        if (this.values[key]) {
          this.textareaValues[key] = this.values[key]
          if (!this.encryptionParams?.[key]?.isEncrypt) {
            this.encryptionParams[key].isEncrypt = false
          }
        }
      })
    }

    setTimeout(() => this.initialLoading = true, 50)
  },
  methods: {
    convertHexToBase64(inputHex) {
      // Remove all whitespace from the hex string
      const cleanHexString = inputHex.replace(/\s+/g, '')

      // Convert the hex string to a binary string
      const binaryString = cleanHexString.match(/.{1,2}/g)
        .map((byte) => String.fromCharCode(parseInt(byte, 16)))
        .join('')

      // Encode the binary string to Base64
      return btoa(binaryString)
    },
    startP12CertInput(field) {
      this.uploadField = field
      this.$refs.p12.click()
    },
    onP12CertFileChange(e) {
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) return
      const reader = new FileReader()

      reader.onload = (event) => {
        const binaryStr = String.fromCharCode.apply(null, new Uint8Array(event.target.result))

        this.values.certificate = btoa(binaryStr)
        this.showP12 = false
        setTimeout(() => {
          this.showP12 = true
        }, 1)
      }

      reader.readAsArrayBuffer(files[0])
      e.target.value = ''
    },
    startCertInput(name, field) {
      this.uploadField = field
      this.$refs[name].click()
    },
    onCertFileChange(e) {
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) return
      const reader = new FileReader()

      reader.onload = (event) => {
        this.values[this.uploadField] = event.target.result
      }
      reader.readAsText(files[0])

      e.target.value = ''
    },
    startInput(name) {
      this.$refs[name].click()
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files

      if (!files.length) return
      const reader = new FileReader()

      reader.onload = (event) => {
        this.values.clientKey = event.target.result
      }
      reader.readAsText(files[0])
    },
    openNewTab(link) {
      window.open(link, '_blank')
    },
    async formatTimeUnits() {
      if (this.defaultValues && this.defaultValues.timeout) {
        const unitTempTimeout = await predictBestTimeUnitFromMsValue(this.defaultValues.timeout)

        this.timeoutUnit = unitTempTimeout.unit
        this.tempTimeout = unitTempTimeout.value
      } else if (this.defaultValues && this.defaultValues.timeout === null) {
        this.defaultValues.timeout = 10000
        this.timeoutUnit = 'Sec'
        this.tempTimeout = '10'
      }
      if (this.defaultValues && this.defaultValues.connectionTimeout) {
        const unitTempConnectionTimeout = await predictBestTimeUnitFromMsValue(this.defaultValues.connectionTimeout)

        this.connectionTimeoutUnit = unitTempConnectionTimeout.unit
        this.tempConnectionTimeout = unitTempConnectionTimeout.value
      } else if (this.defaultValues && this.defaultValues.connectionTimeout === null) {
        this.defaultValues.connectionTimeout = 10000
        this.connectionTimeoutUnit = 'Sec'
        this.tempConnectionTimeout = '10'
      }
      if (this.defaultValues && this.defaultValues.writeTimeout) {
        const unitTempWriteTimeout = await predictBestTimeUnitFromMsValue(this.defaultValues.writeTimeout)

        this.writeTimeoutUnit = unitTempWriteTimeout.unit
        this.tempWriteTimeout = unitTempWriteTimeout.value
      } else if (this.defaultValues && this.defaultValues.writeTimeout === null) {
        this.defaultValues.writeTimeout = 10000
        this.writeTimeoutUnit = 'Sec'
        this.tempWriteTimeout = '10'
      }
    },
    formatRestEncryptionParams(encryptData) {
      this.$emit('setEncryptedFields', encryptData)
      this.tempValuesKey++
    },
    formatPluginEncryptionParams(encryptData) {
      this.$emit('setEncryptedFields', encryptData)
      this.tempValuesKey++
    },
    formatEncryptionParams() {
      const value = this.encryptionParams

      const tempArr = []

      for (const row in value) {

        // eslint-disable-next-line no-prototype-builtins
        if (value.hasOwnProperty(row)) {
          tempArr.push(value[row])
        }
      }

      this.$emit('setEncryptedFields', tempArr)
      this.tempValuesKey++
    },
    formatValues() {
      const value = this.values

      switch (this.type) {
      case 'OUTLOOK_CALENDAR':
        return {
          clientId: value.clientId,
          clientSecret: value.clientSecret,
          tenant: value.tenant
        }
      case 'SSH_KEY':
        return {
          host: value.host,
          password: value.password,
          port: value.port,
          username: value.username,
          privateKey: value.privateKey ? value.privateKey : null,
          passphrase: value.passphrase ? value.passphrase : null
        }
      case 'SSH':
        return {
          host: value.host,
          password: value.password,
          port: value.port,
          username: value.username
        }
      case 'LDAP':
        return {
          baseDn: value.baseDn,
          host: value.host,
          password: value.password,
          port: value.port,
          userDn: value.userDn,
          certificate: value.certificate ? value.certificate : null
        }
      case 'OPEN_AI':
        return {
          token: value.token
        }
      case 'SFTP': {
        return {
          host: value.host,
          password: value.password,
          port: value.port,
          username: value.username,
          privateKey: value.privateKey ? value.privateKey : null,
          passphrase: value.passphrase ? value.passphrase : null
        }
      }
      case 'FTP': {
        return {
          host: value.host,
          password: value.password,
          port: value.port,
          username: value.username
        }
      }
      case 'MQTT':
        return {
          host: value.host,
          password: value.password,
          port: value.port,
          scheme: value.scheme,
          topic: value.topic,
          username: value.username,
          clientId: value.clientId,
          rootCertificate: value.rootCertificate,
          rootCertificatePassword: value.rootCertificatePassword,
          clientCertificate: value.clientCertificate,
          clientPrivateKey: value.clientPrivateKey ? value.clientPrivateKey : null
        }
      case 'RABBITMQ':
        return {
          exchange: value.exchange,
          host: value.host,
          password: value.password,
          port: value.port,
          routingKey: value.routingKey,
          username: value.username,
          virtualHost: value.virtualHost,
          useSslProtocol: value.useSslProtocol
        }
      case 'PAYMENT_SENSE_CONNECT_E':
        return {
          jwt: value.jwt,
          hostUrl: value.hostUrl,
          merchantUrl: value.merchantUrl
        }
      case 'JMS':
        return {
          password: value.password,
          uri: value.uri,
          username: value.username,
          destinationName: value.destinationName,
          destinationType: value.destinationType
        }
      case 'PAYMENT_SENSE_PAC':
        return {
          apiKey: value.apiKey,
          hostUrl: value.hostUrl,
          installerId: value.installerId,
          softwareHouseId: value.softwareHouseId,
          username: value.username
        }
      case 'KAFKA':
        return {
          topic: value.topic,
          groupId: value.groupId,
          bootstrapServers: value.bootstrapServers
        }
      case 'SECURITY':
        return {
          key: value.key,
          passphrase: value.passphrase
        }
      case 'JWT':
        return {
          keyType: value.keyType,
          payload: value.payload,
          privateKey: value.privateKey ? value.privateKey : null,
          signatureAlgorithm: value.signatureAlgorithm
        }
      case 'MONGODB':
        return {
          url: value.url,
          clientKey: value.clientKey
        }
      case 'JDBC':
        return {
          maxPoolSize: value.maxPoolSize,
          connectionTimeout: value.connectionTimeout,
          url: value.url,
          password: value.password,
          username: value.username
        }
      case 'SMTP':
        return {
          host: value.host,
          password: value.password,
          port: value.port,
          username: value.username,
          connectionTimeout: value.connectionTimeout,
          timeout: value.timeout,
          writeTimeout: value.writeTimeout,
          senderAddress: value.senderAddress
        }
      case 'TWILIO':
        return {
          sid: value.sid,
          token: value.token
        }
      case 'REST':
      case 'REST_BASIC':
      case 'REST_BEARER':
        return this.values
      case 'SLACK':
        return {
          channelId: value.channelId,
          token: value.token
        }
      case 'FLOWY':
        return {
          url: value.url,
          username: value.username,
          apiKey: value.apiKey
        }
      case 'PLUGIN':
        return this.values
      case 'AWS':
        return {
          accessKeyId: value.accessKeyId,
          region: value.region,
          s3Bucket: value.s3Bucket,
          secretKey: value.secretKey
        }
      case 'SCRIPT':
        return this.values
      case 'IMAP':
        return {
          host: value.host,
          password: value.password,
          port: value.port,
          username: value.username
        }
      default:
        return null
      }
    },
    handleVisibilityTextareaValues() {
      this.textareaKeys.forEach((key) => {
        if (!this.encryptionParams?.[key]?.isEncrypt) {
          if (this.textareaValues[key] === '●'.repeat(10)) this.textareaValues[key] = null
          this.values[key] = this.textareaValues[key]
        } else {
          this.textareaValues[key] = '●'.repeat(10)
        }
      })
    }
  }
}
</script>
