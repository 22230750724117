import axios from 'axios'

function generateApiUrl(entityName, filters = {}, id = null, options = {}) {
  const baseUrl = '/api/system/'
  let url = `${baseUrl}${entityName}`

  if (id) {
    url += `/${id}`
  }

  const filterKeys = Object.keys(filters)

  if (filterKeys.length > 0) {
    const queryString = filterKeys
      .map((key) => {
        const value = filters[key]

        if (typeof value === 'object' && value.dateFrom && value.dateTill) {
          return `${key}From=${encodeURIComponent(value.dateFrom)}&${key}Till=${encodeURIComponent(value.dateTill)}`
        }

        else if (typeof value === 'object' && value.dateFrom && !value.dateTill) {
          return `${key}From=${encodeURIComponent(value.dateFrom)}`
        }

        else if (typeof value === 'object' && !value.dateFrom && value.dateTill) {
          return `${key}Till=${encodeURIComponent(value.dateTill)}`
        }

        return `${key}=${encodeURIComponent(value)}`
      })
      .join('&')

    url += `?${queryString}&page=${options.page || 1}&size=${options.itemsPerPage || 25}`
  } else {
    url += `?page=${options.page || 1}&size=${options.itemsPerPage || 25}`
  }

  if (options?.sortBy?.length > 0) {
    url += `&orderField=${options.sortBy[0]}&orderDirection=${options.sortDesc[0] ? 'desc' : 'asc'}`
  }

  return url
}

function generatePostApiUrl(entityName, id) {
  const baseUrl = '/api/system/'
  let url = `${baseUrl}${entityName}`

  if (id) {
    url += `/${id}`
  }

  return url
}

async function postEntityEntry(entityName, payload) {
  const url = generatePostApiUrl(entityName)

  try {
    const response = await axios.post(url, payload, { validateStatus: () => true })

    if (response.status !== 200) {

      return { status: response.status, statusText: response.data?.statusText || response.statusText, data: null }
    }

    return response.data?.data || null
  } catch (error) {
    console.error('Error creating entity entry:', error)
    throw error
  }
}

async function putEntityEntry(entityName, id, payload) {
  const url = generatePostApiUrl(entityName, id)

  try {
    const response = await axios.put(url, payload, { validateStatus: () => true })

    if (response.status !== 200) {
      return { status: response.status, statusText: response.data?.statusText || response.statusText, data: null }
    }

    return response.data?.data || null
  } catch (error) {
    console.error('Error creating entity entry:', error)
    throw error
  }
}

async function deleteEntityEntry(entityName, id) {
  const url = generatePostApiUrl(entityName, id)

  try {
    const response = await axios.delete(url)

    if (response.status !== 200) {
      return {
        status: response.response.status,
        statusText: response.response.data?.statusText || response.statusText,
        data: null
      }
    }

    return response.data?.data || null
  } catch (error) {
    console.error('Error by deleting entity entry:', error)
    throw error
  }
}

async function fetchData(entityName, filters = {}, options = {}) {
  const url = generateApiUrl(entityName, filters, null, options)

  try {
    const response = await axios.get(url, { validateStatus: () => true })

    if (response.status === 501 || response.status === 403 || response.status === 500 || response.status === 400) {

      return { status: response.status, data: null }
    }

    return response.data || null
  } catch (error) {

    console.error('Error fetching data:', error.status)

    throw error
  }
}

async function fetchDataById(entityName, id) {
  const url = generateApiUrl(entityName, {}, id, {})

  try {
    const response = await axios.get(url)

    return response.data || null
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

export {
  generateApiUrl, fetchData, fetchDataById, postEntityEntry,
  putEntityEntry, deleteEntityEntry
}
