<template>
  <v-row wrap no-gutters>
    <v-col cols="12" class="py-1">
      <add-key-value
        :key="`${step.id}-set`"
        step-type="SET_VARIABLES"
        type="SET_VARIABLES"
        :can-edit="canEdit"
        :steps="steps"
        :single-step="step"
        :data="properties.set"
        @editor="$emit('openEditor', $event, 'set')"
        @dataChanged="handleChange('set', $event)"
      />
    </v-col>
  </v-row>
</template>

<script>
import StepMixin from './StepMixin'
import AddKeyValue from '../KeyValPairModal'

export default {
  name: 'JSStep',
  components: {
    AddKeyValue
  },
  mixins: [StepMixin],
  props: {
    step: {
      type: Object,
      default: () => {
        return null
      }
    },
    steps: {
      type: Array,
      default: () => []
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  }
}
</script>
