var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{attrs:{"wrap":"","no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{key:`${_vm.step.id}-content`,staticClass:"required-asterisk",attrs:{"outlined":"","dense":"","label":_vm.$lang.labels.content,"required":"","rules":[
        v => !!v || _vm.$lang.labels.required, // Ensure input is not empty
        v => !/^\s/.test(v) || _vm.$lang.errors.noSpaces // No leading spaces
      ],"readonly":!_vm.canEdit,"data-cy":"qr-content"},on:{"input":function($event){return _vm.handleChange('content', $event)}},model:{value:(_vm.properties.content),callback:function ($$v) {_vm.$set(_vm.properties, "content", $$v)},expression:"properties.content"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{key:`${_vm.step.id}-errorCorrectionLevel`,staticClass:"required-asterisk",attrs:{"items":_vm.formattedErrorCorrectionLevels,"label":_vm.$lang.labels.errorCorrectionLevel,"outlined":"","dense":"","required":"","rules":[v => !!v || _vm.$lang.labels.required],"data-cy":"qr-errorCorrectionLevel","readonly":!_vm.canEdit},on:{"change":function($event){return _vm.handleChange('errorCorrectionLevel', $event)}},model:{value:(_vm.properties.errorCorrectionLevel),callback:function ($$v) {_vm.$set(_vm.properties, "errorCorrectionLevel", $$v)},expression:"properties.errorCorrectionLevel"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{key:`${_vm.step.id}-size`,staticClass:"required-asterisk",attrs:{"outlined":"","dense":"","label":_vm.$lang.labels.size,"required":"","rules":[
        v => !!v || _vm.$lang.labels.required, // Field must not be empty
        v => /^[1-9]\d*$/.test(v) || _vm.$lang.errors.greaterThanZeroFUllNumber
      ],"readonly":!_vm.canEdit,"type":"number","data-cy":"qr-size"},on:{"input":function($event){return _vm.handleChange('size', $event)},"keypress":_vm.preventNegativeInput},model:{value:(_vm.properties.size),callback:function ($$v) {_vm.$set(_vm.properties, "size", $$v)},expression:"properties.size"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{key:`${_vm.step.id}-targetObject`,staticClass:"required-asterisk",attrs:{"outlined":"","dense":"","label":_vm.$lang.labels.targetObject,"required":"","rules":[v => !!v || _vm.$lang.labels.required, (v) => _vm.$options.filters.javaVariableConventionRules(v, true) || _vm.$lang.errors.variableJavaWrong],"readonly":!_vm.canEdit,"data-cy":"qr-targetObject"},on:{"input":function($event){return _vm.handleChange('targetObject', $event)}},model:{value:(_vm.properties.targetObject),callback:function ($$v) {_vm.$set(_vm.properties, "targetObject", $$v)},expression:"properties.targetObject"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }