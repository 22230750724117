<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <v-select
        :key="`${step.id}-scriptEngine`"
        v-model="properties.scriptEngine"
        :items="formattedScriptEngines"
        :label="$lang.labels.scriptEngine"
        outlined
        dense
        data-cy="queryBuilder-scriptEngine"
        :readonly="!canEdit"
      ></v-select>
    </v-col>

    <v-col cols="12" class="pt-1 pb-2">
      <add-query-conditions
        :key="`${step.id}-queryConditions`"
        step-type="QUERY_BUILDER"
        type="queryConditions"
        :can-edit="canEdit"
        :vars="steps"
        :step="step"
        :data="properties.queryConditions"
        @dataChanged="handleChangeQueryBuilder('queryConditions', $event)"
      />
    </v-col>

    <v-col cols="12" class="pt-2">
      <v-combobox
        :key="`${step.id}-delimiter`"
        v-model="properties.delimiter"
        data-cy="queryBuilder-delimiter"
        outlined
        dense
        :items="QUERY_DELIMETERS"
        clearable
        hide-no-data
        :rules="[v => v ? v.length < 17 || 'Max 16 characters' : true]"
        :label="$lang.labels.delimiter"
        :readonly="!canEdit"
      ></v-combobox>
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        class="required-asterisk"
        data-cy="queryBuilder-targetObject"
        :label="$lang.labels.targetObject"
        :readonly="!canEdit"
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import AddQueryConditions from '../QueryConditionsPairModal.vue'
import StepMixin from './StepMixin'
import { castValueForStep } from '@/utils/helpers'

import { SCRIPT_ENGINES, QUERY_DELIMETERS } from './constants'

export default {
  name: 'QueryBuilderStep',
  components: {
    AddQueryConditions
  },
  mixins: [StepMixin],
  props: {
    steps: {
      type: Array,
      default: () => []
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      QUERY_DELIMETERS
    }
  },
  computed: {
    formattedScriptEngines() {
      return SCRIPT_ENGINES.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.queryConditions) this.properties.queryConditions = []
  },
  methods: {
    handleChangeQueryBuilder(field, value) {
      this.handleChange(
        field,
        castValueForStep(field, value, {
          shouldBeString: ['queryConditions.query']
        })
      )
    }
  }
}
</script>
