<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="formattedXlsActions"
        :default-value="properties.action"
        step-label="xls"
        :readonly="!canEdit"
        required
        @change="handleChange('action', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-source`"
        v-model="properties.source"
        outlined
        dense
        :label="$lang.labels.source"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="xls-source"
        @input="handleChange('source', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        :label="$lang.labels.targetObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
        data-cy="xls-targetObject"
        @input="handleChange('targetObject', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-checkbox
        :key="`${step.id}-includeHeaders`"
        v-model="properties.includeHeaders"
        :label="$lang.labels.includeHeaders"
        :readonly="!canEdit"
        data-cy="xls-includeHeaders"
        dense
        class="mt-0 pt-0"
      />
    </v-col>
  </v-row>
</template>

<script>
import { ActionSelect } from './components'
import StepMixin from './StepMixin'
import { definitions } from '@/utils/definitions'
import {  castValueForStep } from '@/utils/helpers'

export default {
  name: 'XLSStep',
  components: {
    ActionSelect
  },
  mixins: [StepMixin],
  data() {
    return {
      xlsActions: definitions.XlsStepProperties.properties.action.enum
    }
  },
  computed: {
    formattedXlsActions() {
      return this.xlsActions.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  created() {
    if (!this.properties.action) this.properties.action = 'XLS_TO_JSON'
  },
  methods: {
    handleChange(key, value) {
      this.$set(
        this.properties,
        key,
        castValueForStep(key, value, {
          shouldBeString: ['source', 'targetObject']
        })
      )
    }
  }
}
</script>
