<template>
  <v-row wrap no-gutters>
    <credentials-select
      :key="`${step.id}-credentialname`"
      step-type="SLACK"
      :properties="properties"
      step-label="slack"
      :readonly="!canEdit"
      required
      @change="handleChangeSlack('credentialName', $event)"
    />
    <v-col cols="12" class="pt-1">
      <v-text-field
        :key="`${step.id}-channelId`"
        v-model="properties.channelId"
        outlined
        dense
        :label="$lang.labels.channelId"
        :readonly="!canEdit"
        :rules="[(v) => checkRegexFieldRecipient(v) || $lang.errors.wrongSearchParam]"
      ></v-text-field>
    </v-col>

    <v-col cols="12">
      <script-engines-select
        :key="`${step.id}-scriptEngine`"
        :default-value="properties.scriptEngine"
        step-label="switch"
        :readonly="!canEdit"
        required
        @change="handleChangeSlack('scriptEngine', $event)"
      />
    </v-col>

    <v-col cols="12">
      <v-switch
        :key="`${step.id}-isTemplate`"
        v-model="isTemplate"
        :label="$lang.labels.template"
        @change="isTemplate ? properties.message = '' : ''"
      ></v-switch>
    </v-col>
    <v-col v-if="!isTemplate" cols="12" class="pt-1 position-relative">
      <text-input-with-editor-button
        :key="`${step.id}-message`"
        :rules="[v => !!v || $lang.labels.required, (v) => checkRegexFieldRecipient(v) || $lang.errors.wrongSearchParam]"
        :default-value="properties.message"
        :rows="1"
        :label="$lang.labels.message"
        step-label="slack"
        required
        :readonly="!canEdit"
        @change="handleChangeSlack('message', $event)"
        @openEditor="$emit('openEditor', $event, 'message',
                           { text: '', value: properties.message, parentType: 'message' }
        )"
      />
    </v-col>
    <text-templates-select
      v-if="isTemplate"
      :key="`${step.id}-textTemplate`"
      step-type="SLACK"
      :properties="properties"
      step-label="slack"
      :readonly="!canEdit"
      :required="isTemplate"
      @change="handleChangeSlack('textTemplate', $event)"
    />
    <v-col v-if="properties.textTemplate && isTemplate" cols="12" class="pb-2">
      <add-key-value
        :key="`${step.id}-variables`"
        type="variables"
        step-type="SLACK"
        :can-edit="canEdit"
        :data="properties.variables"
        :single-step="step"
        :text-template-name="properties.textTemplate"
        @dataChanged="handleChangeSlack('variables', $event)"
        @editor="$emit('openEditor', $event, 'variables')"
      ></add-key-value>
    </v-col>
  </v-row>
</template>

<script>
import {
  CredentialsSelect,
  ScriptEnginesSelect,
  TextTemplatesSelect,
  TextInputWithEditorButton
} from './components'
import AddKeyValue from '../KeyValPairModal'
import StepMixin from './StepMixin'
import { castValueForStep } from '@/utils/helpers'

export default {
  name: 'SlackStep',
  components: {
    CredentialsSelect,
    ScriptEnginesSelect,
    TextTemplatesSelect,
    TextInputWithEditorButton,
    AddKeyValue
  },
  mixins: [StepMixin],
  props: {
    step: {
      type: Object,
      default: () => null
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isTemplate: false
    }
  },
  watch: {
    isTemplate: {
      handler(val) {
        this.properties.message = ''
        if (!val) {
          this.properties.textTemplate = null
          this.properties.variables = {}
        }
      }
    }
  },
  created() {
    if (this.properties.textTemplate) {
      this.isTemplate = true
    }
    if (!this.properties.variables) this.$set(this.properties, 'variables', {})
    if (this.properties.locale) {
      this.properties.variables.locale = this.properties.locale
    }
  },
  methods: {
    handleChangeSlack(field, value) {
      this.handleChange(
        field,
        castValueForStep(field, value, {
          shouldBeString: ['credentialName'],
          shouldBeMapOfStrings: ['variables']
        })
      )
    },
    checkRegexFieldRecipient(val) {
      return this.$options.filters.checkComplexRegex(val)
    }
  }
}
</script>
