<template>
  <v-row wrap no-gutters>
    <v-col cols="12">
      <script-engines-select
        :key="`${step.id}-scriptEngine`"
        :default-value="properties.scriptEngine"
        step-label="rest"
        :readonly="!canEdit"
        required
        @change="handleChangeRest('scriptEngine', $event)"
      />
    </v-col>
    <v-col cols="12">
      <encoding-select
        :key="`${step.id}-encoding`"
        :default-value="properties.encoding"
        step-label="rest"
        :readonly="!canEdit"
        required
        @change="handleChangeRest('encoding', $event)"
      />
    </v-col>
    <v-col cols="12">
      <v-select
        :key="`${step.id}-authType`"
        v-model="properties.authType"
        :items="formattedAuthTypes"
        :label="$lang.labels.authType"
        outlined
        dense
        required
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>
    <credentials-select
      :key="`${step.id}-credentialname`"
      step-type="REST"
      :properties="properties"
      step-label="rest"
      :readonly="!canEdit"
      :required="false"
      :input-has-to-be-validated="true"
      @change="handleChangeRest('credentialName', $event)"
    />
    <v-col cols="12">
      <action-select
        :key="`${step.id}-action`"
        :items="REST_ACTIONS"
        :default-value="properties.action"
        step-label="rest"
        :readonly="!canEdit"
        required
        @change="handleChangeRest('action', $event)"
      />
    </v-col>
    <v-col cols="12" class="py-1">
      <add-key-value
        :key="`${step.id}-body`"
        type="body"
        :steps="steps"
        step-type="REST"
        :can-edit="canEdit"
        :single-step="step"
        :data="{body: properties.body || {}, bodyScript: properties.bodyScript || '', bodyTemplate: properties.bodyTemplate || ''}"
        @editor="$emit('openEditor', $event, 'body')"
        @openBodyScriptEditor="$emit('openEditor', $event, 'bodyScript')"
        @dataChanged="handleChangeRest('body', $event)"
        @dataBodyScriptChanged="handleChangeRest('bodyScript', $event)"
        @dataBodyTemplateChanged="handleChangeRest('bodyTemplate', $event)"
      />
    </v-col>
    <v-col cols="12" class="py-1">
      <add-key-value
        :key="`${step.id}-headers`"
        type="headers"
        :steps="steps"
        step-type="REST"
        :can-edit="canEdit"
        :single-step="step"
        :data="{...properties.headers}"
        @dataChanged="handleChangeRest('headers', $event)"
      />
    </v-col>
    <v-col cols="12" class="pt-2 pb-0">
      <v-text-field
        v-if="properties.headers['Content-Type'] === `'multipart/form-data'`"
        :key="`${step.id}-fileExtension`"
        v-model="properties.fileExtension"
        outlined
        dense
        :label="$lang.labels.fileExtension"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
      ></v-text-field>
    </v-col>
    <v-col cols="12" class="pb-1 pt-0">
      <add-key-value
        :key="`${step.id}-queryParams`"
        type="queryParams"
        :steps="steps"
        step-type="REST"
        :can-edit="canEdit"
        :single-step="step"
        :data="{...properties.queryParams}"
        @dataChanged="handleChangeRest('queryParams', $event)"
      />
    </v-col>
    <v-col cols="12" class="position-relative mt-2">
      <text-input-with-editor-button
        :key="`${step.id}-targetUrl`"
        :rules="[v => !!v || $lang.labels.required]"
        :default-value="properties.targetUrl"
        step-label="rest"
        :label="$lang.labels.targetUrl"
        required
        :rows="1"
        :readonly="!canEdit"
        @change="handleChangeRest('targetUrl', $event)"
        @openEditor="$emit('openEditor', $event, 'targetUrl',
                           { text: '', value: properties.targetUrl, parentType: 'targetUrl' }
        )"
      />
    </v-col>
    <v-col cols="9">
      <v-text-field
        :key="`${step.id}-tempTimeout`"
        v-model="tempTimeout"
        outlined
        dense
        :label="$lang.labels.changeableTimeout"
        required
        :rules="[v => !!v || $lang.labels.required]"
        :readonly="!canEdit"
        class="required-asterisk"
      ></v-text-field>
    </v-col>
    <v-col cols="3">
      <v-select
        :key="`${step.id}-timeUnit`"
        v-model="timeUnit"
        :items="timeUnits"
        :label="$lang.labels.unit"
        outlined
        dense
        required
        class="pl-2"
        :readonly="!canEdit"
      ></v-select>
    </v-col>
    <v-col cols="12" class="pt-1">
      <v-text-field
        :key="`${step.id}-restResponseCode`"
        v-model="properties.restResponseCode"
        outlined
        dense
        :label="$lang.labels.restResponseCode"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        :key="`${step.id}-targetObject`"
        v-model="properties.targetObject"
        outlined
        dense
        :label="$lang.labels.restResponseContentTargetObject"
        required
        :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
        :readonly="!canEdit"
        class="required-asterisk"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  ScriptEnginesSelect,
  EncodingSelect,
  CredentialsSelect,
  ActionSelect,
  TextInputWithEditorButton
} from './components'
import AddKeyValue from '../KeyValPairModal'
import StepMixin from './StepMixin'
import { castValueForStep } from '@/utils/helpers'

import {
  AUTH_TYPES,
  REST_ACTIONS,
  TIME_UNITS,
  DEFAULT_TIME_UNIT
} from './constants'
import { predictBestTimeUnitFromMsValue } from '@/utils/helpers'

export default {
  name: 'RestStep',
  components: {
    ScriptEnginesSelect,
    EncodingSelect,
    CredentialsSelect,
    ActionSelect,
    TextInputWithEditorButton,
    AddKeyValue
  },
  mixins: [StepMixin],
  props: {
    steps: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      REST_ACTIONS,
      tempTimeout: 0,
      timeUnits: TIME_UNITS,
      timeUnit: DEFAULT_TIME_UNIT
    }
  },
  computed: {
    formattedAuthTypes() {
      return AUTH_TYPES.map((x) => {
        return { text: this.$lang.status[x], value: x }
      })
    }
  },
  watch: {
    tempTimeout: {
      handler(val) {
        this.properties.timeout = this.$options.filters.unitFormatter(val, this.timeUnit, 'MSec')
      }
    },
    timeUnit: {
      handler(val) {
        this.properties.timeout = this.$options.filters.unitFormatter(this.tempTimeout, val, 'MSec')
      }
    }
  },
  async created() {
    if (this.properties?.timeout) {
      const unitTempTimeout = await predictBestTimeUnitFromMsValue(this.properties.timeout)

      this.timeUnit = unitTempTimeout.unit
      this.tempTimeout = unitTempTimeout.value
    }

    if (!this.properties.headers) this.properties.headers = {}
    if (!this.properties.headers['Content-Type']) this.properties.headers['Content-Type'] = ''
    if (!this.properties.timeout) {
      this.tempTimeout = 15
      this.properties.timeout = '15000'
    }
    if (!this.properties.authType) this.properties.authType = 'NONE'
  },
  methods: {
    handleChangeRest(field, value) {
      this.handleChange(
        field,
        castValueForStep(field, value, {
          shouldBeString: ['bodyTemplate.textTemplate']
        })
      )
    }
  }
}
</script>
